import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./login.css";
import shortLogo from "../../images/Cedric-Millar-Logo-Horizontal1.png";
import shortLogo2 from "../../images/main_side_2.png";
//import shortLogoFR from "../../images/Cederic-Millar-Logo-Horizontal1_FR.png";
import shortLogoFR from "../../images/Cedric-Millar-Logo-Horizontal1_FR.png";
//import cleanSlateLogo from "../../images/cleanSlate.png";
import { ButtonComponent, SwitchComponent } from "@syncfusion/ej2-react-buttons";
import agent, { USERMGMT_URL } from "../../api/agent";
import { DEFAULT_LOCALE, DEFAULT_THEME, setCurrentLocale, setCurrentTheme } from '../Theme/ThemeUtils';
import { useTranslation } from "react-i18next";
import { GrFacebookOption } from "react-icons/gr";
import { AiFillInstagram } from "react-icons/ai";
import { RiLinkedinFill } from "react-icons/ri";
import useMatchMedia from "../../useMatchMedia";
import questLogo from "../../images/quest.png";
import lightHouseLogo from "../../images/lighthouse.png";
import {showToast} from '../Theme/Utils';
import { logOutUser } from "../TokenProvider/TokenProvider";

const requestSource = process.env.REACT_APP_API_SOURCE;
const deviceIdentifier = JSON.stringify({
	width: window.innerWidth,
	height: window.innerHeight,
});

export default function Login(props) {
	const { t, i18n } = useTranslation();

	const [username, setUserName] = useState();
	const [password, setPassword] = useState();
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [newPassword, setNewPassword] = useState();
	const [confirmNewPassword, setConfirmNewPassword] = useState();
	const [disableReset, setDisalbeReset] = useState(true);
	const [langFlag, setLangFlag] = useState("en");

	useEffect(() => {
		i18n.changeLanguage(langFlag);
		setDisalbeReset(true);
	}, [i18n, langFlag]);

	const handleSubmit = async (e) => {
		setLoading(true);
		e.preventDefault();
		setError(false);
		//setTimeout(function(){ console.log(test) }, 500000);
		agent
			.fetchToken(username, password, requestSource, deviceIdentifier)
			.then((result) => {
				if (result?.data === "Incorrect username and/or password.") {
					showToast("Incorrect username and/or password.", 'error', 'light');
					setError(true);
					return;
				}
				//	console.log(result.data);
				props.setToken({
					token: result.data.token,
					username: username,
					access: result.data.permissionsList,
					userid: result.data.user_id,
					locale: result.data.locale,
				});

				if(result?.data?.migrateUser &&  result?.data?.migrationCompleteDate == null){
					// const timer = setTimeout(() => {
					// 	alert("Your account is being migrated....");
					// 	window.location.href = 'https://www.example.com';
					//   }, 5000); // Redirect after 5 seconds

					alert("Your account is being migrated....");
					setTimeout(() => {
						const parts = result?.data?.migrationLink.split("/");
						const secretCode = parts[parts.length - 1];
						window.location.href = USERMGMT_URL + `/carrier-integration?secretCode=${secretCode}`;
					}, 5000);

				} else if (result?.data?.migrateUser &&  result?.data?.migrationCompleteDate != null){
					// const timer = setTimeout(() => {
					// 	alert("Your account has already been migrated. User username is " + result?.data?.azureUPN );
					// 	logOutUser();
					// 	window.location.href = '/TripManagement';
					// 	props.setToken(null);
					//   }, 1000); // Redirect after 1 second

					alert("Your account has already been migrated. User username is " + result?.data?.azureUPN );
					setTimeout(() => {
						logOutUser();
						props.setToken(null);
					}, 1000);
				}

				setCurrentTheme(result?.data?.theme ? result.data.theme : DEFAULT_THEME);
				setCurrentLocale(result?.data?.locale ? result.data.locale : DEFAULT_LOCALE);
				props.setTimer(new Date());
				
			})
			.catch((error) => {

				// props.setToken({
				// 	token: "100",
				// 	username: "dev3",
				// 	access: "",
				// });

				console.log(error);
				// if (error.response === undefined) {
				// 	alert("Unable to reach the server. Please check your internet connection.");
				// 	return;
				// }
				// setError(true);
			})
			.finally(setLoading(false));
	};

	useEffect(() => {
		///console.log(newPassword, confirmNewPassword, newPassword?.length <= 5);
		if (newPassword !== confirmNewPassword) {
			setDisalbeReset(true);
		} else if (newPassword === confirmNewPassword && newPassword?.length <= 5) {
			setDisalbeReset(true);
		} else if (confirmNewPassword === username) {
			setDisalbeReset(true);
		} else if (password === confirmNewPassword) {
			setDisalbeReset(true);
		} else {
			setDisalbeReset(false);
		}
	}, [newPassword, confirmNewPassword]);

	function resetPassword() {
		agent.PostNewPassword(props.token.token, props.token.userid, username, password, newPassword).then((result) => {
			if (result?.data === "Password Changed") {
				props.setToken({ ...props.token, reset: false });
			}
		});
	}

	const styles = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	};

	const inputStyle = {
		maxWidth: "339px",
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "1rem",
	};

	const buttonStyle = {
		width: "100%",
		maxWidth: "339px",
		marginRight: "auto",
		marginLeft: "auto",
	};
	//login-wrapper mt-5
	//flexWrap: "wrap",

	const isDesktopResolution = useMatchMedia("(min-width:768px)", true); //992px
	//const isMobileResolution = useMatchMedia("(min-width:445px)", true);
	const isDesktopResolutionHeight = useMatchMedia("(min-height:800px)", true); //992px

	return (
		<form className='' style={{ height: "100vh", overflowY: "hidden", minHeight: "560px" }} onSubmit={handleSubmit}>
			{/* <div style={{ height: "100vh", width: "100%", minHeight: "100%" }}> */}
			<div className='row' style={{ display: "flex", flexWrap: "wrap", height: "100%", margin: 0 }}>
				<div className='col-md-6 col-xs-12' style={styles}>
					<div>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",

								margin: "auto",
								maxWidth: "258px",
							}}
						>
							<img
								src={langFlag === "fr" ? shortLogoFR : shortLogo}
								alt='logo'
								style={{ height: "100%", alignContent: "center", overflow: "hidden" }}
							/>
						</div>
						<div
							style={{
								textAlign: "center",
								fontFamily: "Nunito Sans",
								fontWeight: "bold",
								marginTop: "10%",
								marginRight: "25%",
								marginLeft: "25%",
								marginBottom: "5%",
							}}
						>
							{t("A TRUSTED APPROACH TO SUPPLY CHAIN SOLUTIONS")}
						</div>

						<div
							id='sd'
							style={{
								display: "flex",
								alignItems: "right",
								justifyContent: "right",
								maxWidth: "339px",
								marginBottom: ".5rem",
								marginLeft: "auto",
								marginRight: "auto",
							}}
						>
							<SwitchComponent
								id='switch3'
								cssClass='handle-text'
								checked={true}
								onClick={(x) => {
									console.log(x);
								}}
								change={(x) => {
									if (x.checked) {
										setLangFlag("en");
									} else {
										setLangFlag("fr");
									}
								}}
							/>
						</div>

						<input
							type='text'
							className='form-control'
							placeholder={t("Username")}
							id='usr'
							style={inputStyle}
							onChange={(e) => {
								setUserName(e.target.value);
							}}
							disabled={props?.token?.reset}
						/>

						<input
							type='password'
							placeholder={t("Password")}
							className='form-control'
							id='pwd'
							style={inputStyle}
							onChange={(e) => setPassword(e.target.value)}
							disabled={props?.token?.reset}
						/>
						{props?.token?.reset && (
							<div
								style={{
									fontFamily: "Nunito Sans",
									maxWidth: "339px",
									fontSize: "13px",
									textAlign: "center",
									marginLeft: "auto",
									marginRight: "auto",

									marginTop: "1rem",
									marginBottom: "1rem",
									color: "black",
								}}
							>
								<div>{t("Please update your password")}</div>
							</div>
						)}
						{props?.token?.reset && (
							<input
								style={inputStyle}
								type='password'
								className='form-control'
								placeholder={t("New Password")}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
						)}
						{props?.token?.reset && (
							<input
								type='password'
								placeholder={t("Confirm Password")}
								className='form-control'
								style={inputStyle}
								onChange={(e) => setConfirmNewPassword(e.target.value)}
							/>
						)}
						<div className='d-flex justify-content-center mx-1'>
							<label className='label-wrapper' hidden={!error}>
								{t("Incorrect credentials. Please try again.")}
							</label>
							<label className='label-wrapper mb-2 mx-1' hidden={newPassword === confirmNewPassword}>
								{t("Passwords do not match")}
							</label>
						</div>
						{disableReset && newPassword && (
							<div className='d-flex justify-content-center mx-1'>
								<label className='label-wrapper mb-2 mx-1' hidden={!(newPassword?.length <= 5)}>
									{t("Passwords must be at least 6 characters long")}
								</label>
							</div>
						)}
						{disableReset && newPassword === username && newPassword?.length > 0 && (
							<div className='d-flex justify-content-center mx-1'>
								<label className='label-wrapper mb-2 mx-1'>{t("Passwords cannot be the same as the username")}</label>
							</div>
						)}
						{disableReset && newPassword === password && newPassword?.length > 0 && (
							<div className='d-flex justify-content-center mx-1'>
								<label className='label-wrapper mb-2 mx-1'>
									{t("Passwords cannot be the same as the old password")}
								</label>
							</div>
						)}

						<div
							style={{
								display: "flex",
								flexDirection: "row",
								maxWidth: "339px",
								marginRight: "auto",
								marginLeft: "auto",
							}}
						>
							{/* <div className='form-check'>
								<input className='form-check-input' type='checkbox' value='' id='flexCheckDefault' />
								<label
									className='form-check-label'
									for='flexCheckDefault'
									style={{ color: "#8D8D8D", fontSize: "13px", fontFamily: "Nunito Sans" }}
								>
									Remember Me
								</label>
							</div> */}
							{/* <div
								style={{
									marginLeft: "auto",
									marginRight: "0",
									color: "#8D8D8D",
									fontSize: "13px",
									fontFamily: "Nunito Sans",
								}}
							>
								Forgot Password
							</div> */}
						</div>
						<div
							style={{
								fontFamily: "Nunito Sans",
								maxWidth: "339px",
								fontSize: "13px",
								textAlign: "center",
								marginLeft: "auto",
								marginRight: "auto",

								marginTop: "1rem",
								marginBottom: "1rem",
								color: "#8D8D8D",
							}}
						>
							<div style={{ marginRight: "20px", marginLeft: "20px" }}>
								{t("By logging in, you agree to our")}
								<a
									href='https://www.cedricmillar.com/privacy-policy/'
									color='#8D8D8D'
									style={{ marginLeft: 0, marginRight: 0, paddingRight: "3px" }}
								>
									<span style={{ textDecoration: "underline", color: "#8D8D8D" }}> {t("Privacy Policy")}</span>
								</a>
								{t("and")}
								<a href='https://www.cedricmillar.com/carriers-terms'>
									<span style={{ textDecoration: "underline", color: "#8D8D8D" }}> {t("Terms of Use")} </span>
								</a>
							</div>
						</div>
						{!props?.token?.reset && (
							<div style={{ width: "100%", maxWidth: "339px", marginRight: "auto", marginLeft: "auto" }}>
								<button style={{width: "100%", fontFamily: "Nunito Sans",  fontWeight: "bold", marginTop: "2rem",}} className="my-custom-btn" type='submit'>
									{t("LOG IN")}
								</button>
							</div>
						)}
						{props?.token?.reset && (
							<div style={buttonStyle}>
								<ButtonComponent
									type='none'
									style={{ width: "100%" }}
									//className='e-info'
									disabled={disableReset}
									cssClass='e-custom'
									onClick={(click) => {
										resetPassword();
										click.preventDefault();
									}}
								>
									{loading && <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>}
									{t("Reset & Login")}
								</ButtonComponent>

								<div className='col d-flex justify-content-center'>
									<ButtonComponent
										style={{ width: "100%", color: "black", backgroundColor: "#fafafa" }}
										onClick={(click) => {
											click.preventDefault();
											setDisalbeReset(false);
											props.setToken("");
											setConfirmNewPassword("");
											setNewPassword("");
										}}
									>
										{t("Cancel")}
									</ButtonComponent>
								</div>
							</div>
						)}
						<div
							style={{
								textAlign: "center",
								marginTop: "1.5rem",
								marginBottom: "1.5rem",
								fontFamily: "Nunito Sans",
								color: "#8D8D8D",
								fontSize: "13px",
							}}
						>
							{t("Need Assistance?")}
							<a href='mailto:helpdesk@cedricmillar.com' target='_blank' rel='noreferrer'>
								<span style={{ color: "black" }}> {t("Contact us here")} </span>
							</a>
						</div>
						{isDesktopResolution && (
							<div
								style={{
									maxWidth: "339px",
									marginRight: "auto",
									marginLeft: "auto",
									width: "100%",
									textAlign: "center",
									marginTop: "1.5rem",
									color: "#8D8D8D",
								}}
							>
								<a href='https://www.facebook.com/cedricmillarcanada' target='_blank' rel='noreferrer'>
									<GrFacebookOption style={{ margin: "5px", color: "#8D8D8D" }}></GrFacebookOption>
								</a>
								<a href='https://www.instagram.com/cedricmillar_integrated/' target='_blank' rel='noreferrer'>
									<AiFillInstagram style={{ margin: "5px", color: "#8D8D8D" }}></AiFillInstagram>
								</a>
								<a href='https://www.linkedin.com/company/cedric-millar' target='_blank' rel='noreferrer'>
									<RiLinkedinFill style={{ margin: "5px", color: "#8D8D8D" }}></RiLinkedinFill>
								</a>
							</div>
						)}

						{isDesktopResolution && isDesktopResolutionHeight && (
							<div
								// style={{
								// 	fontFamily: "Nunito Sans",
								// 	textAlign: "center",
								// 	fontWeight: "bold",
								// 	//marginTop: "1rem",
								// 	bottom: "2rem",
								// 	position: "absolute",
								// 	marginRight: "auto",
								// 	marginLeft: "auto",
								// }}
								style={{
									textAlign: "center",
									marginTop: "6rem",
									marginBottom: "0",
									fontFamily: "Nunito Sans",
									//color: "#8D8D8D",
									fontSize: "13px",
									fontWeight: "bold",
									position: "absolute",
									bottom: "30px",
									right: "64%",
									left: "15%",
									//marginLeft: langFlag === "en" ? "12%" : "18%",
								}}
								// style={{

								// 	maxWidth: "339px",
								// 	//marginRight: "auto",
								// 	//marginLeft: "auto",
								// 	display: "flex",
								// 	//marginLeft: langFlag === "en" ? "auto" : "50%",
								// 	width: "100%",
								// 	//textAlign: "center",
								// 	//marginTop: "1.5rem",
								// 	alignContent: "baseline",
								// 	//bottom: "2rem",
								// 	//position: "absolute",
								// 	fontWeight: "bold",
								// 	fontFamily: "Nunito Sans",
								// }}
							>
								<div style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center", overflow: "hidden" }}>
									{t("Moved By Intelligence")}
								</div>
							</div>
						)}
					</div>
				</div>

				<div
					//src={shortLogo2}
					alt='logo'
					//	className='container'
					style={{
						//maxHeight: "100vh",
						//maxWidth: "100%",
						flex: 1,
						//objectFit: "cover",
						//objectPosition: "0 100% ",
						//height: "100%",
						//width: "100%",
						//margin: 0,
						//padding: 0,
						//backgroundPosition: "100% 100%",
						backgroundSize: "cover",
						backgroundImage: `url(${shortLogo2})`,
						//opacity: ".6",
						margin: 0,
						padding: 0,
						//background: "rgba(0, 0, 0, 0.8)",
					}}
					//style={{ }}
					className='col-md-6 col-xs-6 container'
				>
					<div
						style={{
							backgroundColor: "rgba(0, 0, 0, 0.6)",
							position: "relative",
							// top: 0,
							// left: 0,
							width: "100%",
							height: "100%",
							//margin: 0,
							padding: 0,

							//display: "table",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<div
							className='container'
							style={{
								display: "flex",
								flexDirection: "column",
								minWidth: "228px",
								maxWidth: "424px",
							}}
						>
							<img
								alt='quest logo'
								//height={52}
								width={"100%"}
								src={questLogo}
								style={{
									//	minWidth: 456,
									//display: "table-cell",
									//textAlign: "center",
									//verticalAlign: "middle",
									//margin: 0,
									//marginTop: "50%",
									//marginRight: "50%",
									//marginLeft: "50%",
									//width: "50%",
									//height: "100%",
									//margin: "auto 0",
									//position: isDesktopResolution && "absolute",
									//position: isDesktopResolution && "relative",
									//top: "50%",
									//left: "50%",
									//display: "block",
									//marginLeft: "auto",
									//marginRight: "auto",
									//marginRight: "25%",
									//marginLeft: "25%",
									//marginTop: "25%",
									//marginBottom: "25%",
									//transform: "translate(-50%, -50%)",
									//	margin: "auto",
									marginTop: "10px",
								}}
							/>
							<img
								alt='clean slate logo'
								//height={52}
								//width={228}
								src={lightHouseLogo}
								style={{
									//minWidth: 228,
									//display: "table-cell",
									//textAlign: "center",
									//verticalAlign: "middle",
									//margin: 0,
									//marginTop: "50%",
									//marginRight: "50%",
									//marginLeft: "50%",
									//width: "50%",
									//height: "100%",
									//margin: "auto 0",
									//position: isDesktopResolution && "absolute",
									//position: isDesktopResolution && "relative",
									//top: "50%",
									//left: "50%",
									//display: "block",
									//marginLeft: "auto",
									//marginRight: "auto",
									//marginRight: "25%",
									//marginLeft: "25%",
									//marginTop: "25%",
									//marginBottom: "25%",
									//transform: "translate(-50%, -50%)",
									//margin: "auto",
									marginTop: "10px",
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
}
//<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
Login.propTypes = {
	setToken: PropTypes.func.isRequired,
	setTimer: PropTypes.func.isRequired,
};
